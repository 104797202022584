<template>
  <Component v-bind:is="header"/>
</template>

<script>
  export default {
    computed: {
      header() {
        return this.$store.getters["system/header"];
      }
    }
  };
</script>